import { postRequest, getRequest } from './index'
// 交易记录
export const summaryGetRealTimeOrder = (data, successCallback, failureCallback) => {
  postRequest('/trade/order/summary/getOrderTradeRecodePage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 运营平台-整体指标
export const summaryGetTradeOverallIndicator = (data, successCallback, failureCallback) => {
  getRequest('/trade/order/summary/getTradeOverallIndicator', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 监控大屏-交易额同比环比
export const orderSummaryGetPlatTradeAmount = (data, successCallback, failureCallback) => {
  postRequest('/trade/order/summary/getPlatTradeAmount', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 监控大屏-价格走势
export const orderSummaryGetTradePriceTrend = (data, successCallback, failureCallback) => {
  postRequest('/trade/order/summary/getPlatTradePriceTrend', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 监控大屏-交易品类分布
export const orderSummaryGetPlatTradeCategoryDistribution = (data, successCallback, failureCallback) => {
  postRequest('/trade/order/summary/getPlatTradeCategoryDistribution', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 监控大屏-热销品类
export const orderSummaryGetPlatHotProductCategoryData = (data, successCallback, failureCallback) => {
  postRequest('/trade/order/summary/getPlatHotProductCategoryData', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
