<template>
  <!-- 价格走势 -->
  <div class="charts-content">
    <div ref="priceTrend" class="price-trend" />
  </div>
</template>

<script>
import { orderSummaryGetTradePriceTrend } from '@/api/monitor'
export default {
  props: {
    params: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    params: {
      handler(newval) {
        const obj = { ...newval, categoryName: newval.categoryName1 }
        if ([0, 1].includes(newval.type)) this.getList(obj)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getList(val) {
      this.myChart = this.$echarts.init(this.$refs['priceTrend'])
      this.myChart.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      orderSummaryGetTradePriceTrend(val, res => {
        const colorArr = [
          '#1F8EFF',
          '#23C343',
          '#F7765B',
          '#FCC248',
          '#1F8EFF'
        ]
        const series = []
        let allProduct = []
        if (res.data.tradePriceTrends && res.data.tradePriceTrends.length > 0) {
          res.data.tradePriceTrends.forEach((item, index) => {
            allProduct.push(item.goodsName)
            series.push({
              name: item.goodsName,
              type: 'line',
              symbol: 'circle',
              stack: 'Total',
              lineStyle: { color: colorArr[index % 5] },
              data: item.prices
            })
          })
        } else {
          allProduct = []
          series.push({
            name: '',
            type: 'line',
            symbol: 'circle',
            stack: 'Total',
            data: []
          })
        }
        this.rendCharts(res.data.dateList, series, allProduct)
      })
    },
    rendCharts(echartsObj = [], series = [], allProduct = []) {
      this.myChart.hideLoading()
      this.myChart.clear()
      const option = {
        title: {
          text: '单位：万元',
          x: '-6px',
          textStyle: {
            color: '#929CA7',
            fontSize: '14',
            fontWeight: '500'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: allProduct,
          bottom: '0',
          textStyle: {
            color: '#000'
          },
          icon: 'circle',
          itemHeight: 8
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '10%',
          top: '11.5%',
          containLabel: true
        },
        toolbox: {},
        xAxis: {
          type: 'category',
          boundaryGap: ['0.5', '1'],
          data: echartsObj || [],
          axisLine: {
            lineStyle: {
              color: '#e7e7e7'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#e7e7e7'
            }
          },
          axisLabel: {
            color: '#4A4A4A' // 坐标值得具体的颜色
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#4A4A4A' // 坐标值得具体的颜色
          }
        },
        series: series
      }
      this.myChart.setOption(option)
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-content {
  width: 100%;
  height: 400px;
  .price-trend {
    width: 100%;
    height: 100%;
  }
}
</style>
