<template>
  <div class="charts-content">
    <div ref="hotsellCategory" class="hotsell-category" />
  </div>
</template>

<script>
import { orderSummaryGetPlatHotProductCategoryData } from '@/api/monitor'
export default {
  props: {
    params: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    params: {
      handler(newval) {
        const obj = { ...newval, dimension: newval.dimension2, categoryName: newval.categoryName3 }
        if ([0, 3].includes(newval.type)) this.getList(obj)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getList(val) {
      this.myChart = this.$echarts.init(this.$refs['hotsellCategory'])
      this.myChart.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      orderSummaryGetPlatHotProductCategoryData(val, res => {
        const salvProValue = []; const salvProName = []
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            salvProValue.push(this.params.dimension === '00' ? (item.goodsWeight || 0) : (item.goodsAmount || 0))
            salvProName.push(item.goodsName)
          })
        }
        this.rendCharts(salvProValue, salvProName)
      })
    },
    rendCharts(salvProValue = [], salvProName = []) {
      const option = {
        title: {
          text: this.params.dimension === '00' ? '单位：吨' : '单位：万元',
          x: '-6px',
          textStyle: {
            color: '#929CA7',
            fontSize: '14',
            fontWeight: '500'
          }
        },
        grid: {
          left: '0',
          right: '2%',
          bottom: '0',
          top: '8%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: 'rgba(2,126,255,0.08)',
              type: 'solid',
              width: 36
            }
          }
        },
        xAxis: {
          show: true,
          type: 'value',
          axisLabel: {
            show: true,
            color: '#4A4A4A'
          }
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLabel: {
              show: true,
              color: '#4A4A4A'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: salvProName
          }
        ],
        series: [
          {
            name: this.params.dimension === '00' ? '数量：' : '交易额：',
            type: 'bar',
            zlevel: 1,
            label: {
              // 柱图头部显示值
              show: true,
              position: 'right',
              color: '#333',
              fontSize: '12px',
              formatter: params => {
                return params.value[params.encode.x[0]]
              }
            },
            itemStyle: {
              color: '#1F8EFF'
            },
            barWidth: 16,
            data: salvProValue
          }
        ]
      }
      if (this.myChart) this.myChart.hideLoading()
      this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-content {
  width: 100%;
  height: 400px;
  .hotsell-category {
    width: 100%;
    height: 100%;
  }
}
</style>
