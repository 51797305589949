<template>
  <!-- 交易统计 -->
  <div>
    <!-- 上面部分的  整体指标 -->
    <div class="transaction-statistic-top">
      <h1 class="page-title">
        整体指标
      </h1>
      <div class="top-data">
        <div>
          <p class="title">
            累计交易数量
          </p>
          <p class="total elp-1">
            {{
              tradeOverallIndicatorData.cumulativeTradeWeight
                .totalTradeWeight || 0
            }}<i>吨</i>
          </p>
          <p class="newly-increased elp-1">
            <i
              v-if="
                tradeOverallIndicatorData.cumulativeTradeWeight
                  .currentMonthTradeWeightAdd < 0
              "
              class="add"
            >本月新减{{
              tradeOverallIndicatorData.cumulativeTradeWeight
                .currentMonthTradeWeightAdd || 0
            }}吨<i class="el-icon-bottom" /></i>
            <i
              v-else
              class="subtract"
            >本月新增{{
              tradeOverallIndicatorData.cumulativeTradeWeight
                .currentMonthTradeWeightAdd || 0
            }}吨<i class="el-icon-top" /></i>
            <!-- 单笔最高{{
              tradeOverallIndicatorData.cumulativeTradeWeight
                .orderWeightHeaviest || 0
            }}吨，平均{{
              tradeOverallIndicatorData.cumulativeTradeWeight.orderWeightAvg ||
                0
            }}吨 -->
          </p>
          <img class="icon" src="@/assets/img/icon4.png" alt="icon">
        </div>
        <div>
          <p class="title">
            累计交易额
          </p>
          <p class="total elp-1">
            {{
              tradeOverallIndicatorData.cumulativeTradeAmount.totalTradeAmount
                | numberToCurrency
            }}<i>万元</i>
          </p>
          <p class="newly-increased elp-1">
            <i
              v-if="
                tradeOverallIndicatorData.cumulativeTradeWeight
                  .currentMonthTradeAmountAdd < 0
              "
              class="add"
            >本月新减{{
              tradeOverallIndicatorData.cumulativeTradeAmount
                .currentMonthTradeAmountAdd | numberToCurrency
            }}万元<i class="el-icon-bottom" /></i>
            <i
              v-else
              class="subtract"
            >本月新增{{
              tradeOverallIndicatorData.cumulativeTradeAmount
                .currentMonthTradeAmountAdd | numberToCurrency
            }}万元<i class="el-icon-top" /></i>
            <!-- 单笔最高{{
              tradeOverallIndicatorData.cumulativeTradeAmount
                .orderAmountHeaviest || 0
            }}吨，平均{{
              tradeOverallIndicatorData.cumulativeTradeAmount.orderAmountAvg ||
                0
            }}吨 -->
          </p>
          <img class="icon" src="@/assets/img/icon1.png" alt="icon">
        </div>
        <div>
          <p class="title">
            平台客户
          </p>
          <p class="total elp-1">
            {{ tradeOverallIndicatorData.platCustomer.totalCustomer || 0
            }}<i>人</i>
          </p>
          <p class="newly-increased elp-1">
            <i
              v-if="
                tradeOverallIndicatorData.cumulativeTradeWeight
                  .currentMonthCustomerAdd > 0
              "
              class="add"
            >本月新减{{
              tradeOverallIndicatorData.cumulativeTradeWeight
                .currentMonthCustomerAdd || 0
            }}人<i class="el-icon-bottom" /></i>
            <i
              v-else
              class="subtract"
            >本月新增{{
              tradeOverallIndicatorData.cumulativeTradeWeight
                .currentMonthCustomerAdd || 0
            }}人<i class="el-icon-top" /></i>
          </p>
          <img class="icon" src="@/assets/img/icon2.png" alt="icon">
        </div>
        <div>
          <p class="title">
            订单数
          </p>
          <p class="total elp-1">
            {{ tradeOverallIndicatorData.orderCount.totalOrderCount || 0
            }}<i>笔</i>
          </p>
          <p class="newly-increased elp-1">
            <i
              v-if="
                tradeOverallIndicatorData.orderCount
                  .currentMonthOrderAdd < 0
              "
              class="add"
            >本月新减{{
              tradeOverallIndicatorData.orderCount
                .currentMonthOrderAdd || 0
            }}笔<i class="el-icon-bottom" /></i>
            <i
              v-else
              class="subtract"
            >本月新增{{
              tradeOverallIndicatorData.orderCount
                .currentMonthOrderAdd || 0
            }}笔<i class="el-icon-top" /></i>
          </p>
          <img class="icon" src="@/assets/img/icon3.png" alt="icon">
        </div>
      </div>
    </div>
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInlineSearch"
      :form-item-arr="formItemArr"
      @resetFormInline="resetFormInline"
    >
      <!-- <el-form-item label="商品类别" label-width="80px">
        <el-cascader
          ref="goodsCategoryRef"
          placeholder="请选择"
          :options="categoryOption"
          :props="categoryCascadeProps"
          clearable
          @change="categoryChange"
        >
        </el-cascader>
      </el-form-item> -->
      <el-form-item label="交易日期">
        <el-date-picker
          v-model="formInlineSearch.daterange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDaterange"
        />
      </el-form-item>
    </FormSearch>
    <div class="pageContainerTable">
      <h3 class="title">
        统计图表
      </h3>
      <div class="charts-box charts-box-two">
        <div class="maximumWidth">
          <div class="condition">
            <span>交易同比环比</span>
            <!-- <el-select v-model="formInline.name">
            <el-option value="日维度" label="日维度" />
          </el-select> -->
          </div>
          <VolumeOfTrade :params="formInline" />
        </div>
        <div class="maximumWidth">
          <div class="condition">
            <span>价格走势</span>
            <el-select
              v-model="formInline.categoryName1"
              @change="formInline.type = 1"
            >
              <el-option
                v-for="(item, index) in secondLevelArr"
                :key="index"
                :value="item.goodsName"
                :label="item.goodsName"
              />
            </el-select>
          </div>
          <PriceTrend :params="formInline" />
        </div>
      </div>
      <div class="charts-box charts-box-two">
        <div class="maximumWidth">
          <div class="condition">
            <span>交易{品类/地域}分布</span>
            <div>
              <!-- <el-select
                v-model="formInline.categoryName2"
                @change="formInline.type = 2"
              >
                <el-option
                  v-for="(item, index) in secondLevelArr"
                  :key="index"
                  :value="item.goodsName"
                  :label="item.goodsName"
                />
              </el-select> -->
              <el-select
                v-model="formInline.dimension1"
                @change="formInline.type = 2"
              >
                <el-option value="00" label="交易数量" />
                <el-option value="01" label="交易额" />
              </el-select>
            </div>
          </div>
          <TransactionCategory :params="formInline" />
        </div>
        <div class="maximumWidth">
          <div class="condition">
            <span>热销品类排号</span>
            <div>
              <el-select
                v-model="formInline.categoryName3"
                @change="formInline.type = 3"
              >
                <el-option
                  v-for="(item, index) in secondLevelArr"
                  :key="index"
                  :value="item.goodsName"
                  :label="item.goodsName"
                />
              </el-select>
              <el-select
                v-model="formInline.dimension2"
                @change="formInline.type = 3"
              >
                <el-option value="00" label="交易数量" />
                <el-option value="01" label="交易额" />
              </el-select>
            </div>
          </div>
          <HotsellCategory :params="formInline" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import VolumeOfTrade from './components/volumeOfTrade.vue'
import PriceTrend from './components/priceTrend.vue'
import TransactionCategory from './components/transactionCategory.vue'
import HotsellCategory from './components/hotsellCategory.vue'
import { goodsGetGoodsCategoryList } from '@/api/shoppingMall'
import { summaryGetTradeOverallIndicator } from '@/api/monitor'
export default {
  components: {
    FormSearch,
    VolumeOfTrade,
    PriceTrend,
    TransactionCategory,
    HotsellCategory
  },
  data() {
    return {
      // 日期
      secondLevelArr: [],
      formInline: {
        daterange: [],
        categoryName1: '',
        categoryName2: '',
        categoryName3: '',
        dimension1: '00',
        dimension2: '00',
        endDate: '',
        startDate: '',
        dataSource: '',
        keyword: '',
        customerIdentity: '',
        type: null
      },
      formInlineSearch: {
        dataSource: '',
        keyword: '',
        customerIdentity: '',
        daterange: []
      },
      formItemArr: [
        {
          type: 'select',
          label: '业务类型',
          value: 'dataSource',
          pLabel: 'dictName',
          pValue: 'dictId',
          child: this.$store.getters.getDictionaryItem('business_type')
        },
        { type: 'input', label: '客户名称', value: 'keyword' },
        {
          type: 'select',
          label: '客户身份',
          value: 'customerIdentity',
          pLabel: 'dictName',
          pValue: 'dictId',
          child: [
            { dictId: '00', dictName: '买家' },
            { dictId: '01', dictName: '卖家' }
          ]
        }
      ],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName'
      },
      // 商品名称
      categoryOption: [],
      // 整体指标
      tradeOverallIndicatorData: {
        cumulativeTradeAmount: {},
        cumulativeTradeWeight: {},
        orderCount: {},
        platCustomer: {},
        tradeAmount: {},
        tradeWeight: {}
      }
    }
  },
  created() {
    this.getCategoryList()
    this.getSummaryGetTradeOverallIndicator()
  },
  methods: {
    // 重置按钮
    resetFormInline() {
      this.formInline.startDate = ''
      this.formInline.endDate = ''
      this.formInlineSearch.startDate = ''
      this.formInlineSearch.endDate = ''
      this.formInlineSearch.daterange = []
      this.formInline.keyword = ''
      this.formInline.dataSource = ''
      this.formInline.customerIdentity = ''
    },
    // 获取整体指标
    getSummaryGetTradeOverallIndicator() {
      summaryGetTradeOverallIndicator('', res => {
        this.tradeOverallIndicatorData = res.data
      })
    },
    getdata() {
      this.formInline.type = 0
      this.formInline.startDate = this.formInlineSearch.startDate
      this.formInline.endDate = this.formInlineSearch.endDate
      this.formInline.keyword = this.formInlineSearch.keyword
      this.formInline.dataSource = this.formInlineSearch.dataSource
      this.formInline.customerIdentity = this.formInlineSearch.customerIdentity
    },
    // 获取日期
    getDaterange(arr) {
      if (!arr) {
        this.formInlineSearch.startDate = this.formInlineSearch.endDate = ''
        return
      }
      this.formInlineSearch.startDate = arr[0]
      this.formInlineSearch.endDate = arr[1]
    },
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        if (res.data.length > 0) {
          this.secondLevelArr = [...res.data[0].children]
          this.formInline.categoryName1 = this.secondLevelArr[0].goodsName
          this.formInline.categoryName2 = this.secondLevelArr[0].goodsName
          this.formInline.categoryName3 = this.secondLevelArr[0].goodsName
          this.formInline.type = 0
        }
        this.categoryOption = [...res.data]

        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map(item => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },
    // 品名
    categoryChange(val) {
      if (!val || val.length === 0) {
        this.formInline.goodsTwoCategoryName = ''
        this.formInline.goodsMaterialName = ''
        this.goodsMaterialOption = []
        return
      }
      const goodsArr = this.$refs.goodsCategoryRef.getCheckedNodes()[0]
        .pathLabels
      this.formInline.goodsTwoCategoryName = goodsArr[2]
    }
  }
}
</script>

<style lang="scss" scoped>
.pageContainerTable {
  background-color: #fff;
  .title {
    height: 68px;
    padding-left: 24px;
    border-bottom: 1px solid #e7e7e7;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
    line-height: 68px;
    background-color: #fff;
    font-weight: 500;
  }
}
.transaction-statistic-top {
  .page-title {
    height: 68px;
    padding-left: 24px;
    border-bottom: 1px solid #e7e7e7;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
    line-height: 68px;
    background-color: #fff;
  }
}
.top-data {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 16px;
  > div {
    position: relative;
    flex: 1;
    height: 156px;
    margin-right: 16px;
    background: rgba(251, 251, 251, 1);
    padding: 10px 0;
    color: #000;
    .newly-increased {
      color: #666;
      font-size: 14px;
      margin: 0 24px 0 20px;
      i {
        color: #63ae34;
        font-style: normal;
      }
      .subtract {
        color: #e86d54;
        i {
          color: #e86d54;
        }
      }
    }
    .total {
      color: #000;
      font-size: 34px;
      font-weight: 700;
      margin: 0 20px 16px 20px;
      i {
        font-style: normal;
        color: #999;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .icon {
      position: absolute;
      top: 24px;
      right: 24px;
      height: 56px;
      width: 56px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 24px 0 12px 20px;
      color: #333;
      font-size: 14px;
    }
  }
}
.charts-box {
  padding: 24px;
  border: 1px solid #e7e7e7;
  margin: 16px 24px;
  border-radius: 4px;
  .maximumWidth {
    width: calc(50% - 32px);
    display: inline-block;
    padding: 24px;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
  }
  .maximumWidth:first-of-type {
    margin-right: 16px;
  }
  .condition {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    .el-select {
      width: 148px;
      height: 28px;
    }
  }
}
.charts-box-two {
  border: 0;
  padding: 0;
  display: flex;
  align-content: space-between;
}
</style>
